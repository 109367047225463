import { UserStore } from './user.store';
import { RoleStore } from './role.store';
import { CategoryStore } from './category.store';
import { CategoryFileStore } from './category-file.store';

export const stores: any[] = [
  UserStore,
  RoleStore,
  CategoryStore,
  CategoryFileStore,
];
