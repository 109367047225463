import { createAction, props } from '@ngrx/store';
import { CategoryFile } from '../../../interfaces/common/category-file';
import { UpdateStr } from '@ngrx/entity/src/models';

export const getCategoryFileRequest = createAction('[CategoryFile/API] Get Category File Request', props<{ id: string }>());
export const getCategoryFile = createAction('[CategoryFile/API] Get Category File', props<{ categoryFile: CategoryFile }>());
export const getCategoryFileFailed = createAction('[CategoryFile/API] Get Category File Failed', props<{ error: any }>());

export const loadCategoryFilesRequest = createAction('[CategoryFile/API] Load Category Files Request');
export const loadCategoryFiles = createAction('[CategoryFile/API] Load Category Files', props<{ categoryFiles: CategoryFile[] }>());
export const loadCategoryFilesFailed = createAction('[CategoryFile/API] Load Category Files Failed', props<{ error: any }>());

export const addCategoryFileRequest = createAction('[CategoryFile/API] Add CategoryFile Request', props<{ categoryFile: CategoryFile }>());
export const addCategoryFile = createAction('[CategoryFile/API] Add CategoryFile', props<{ categoryFile: CategoryFile }>());
export const addCategoryFileFailed = createAction('[CategoryFile/API] Add Category File Failed', props<{ error: any }>());

export const updateCategoryFileRequest = createAction('[CategoryFile/API] Update CategoryFile Request', props<{ categoryFile: CategoryFile }>());
export const updateCategoryFile = createAction('[CategoryFile/API] Update CategoryFile', props<{ categoryFile: UpdateStr<CategoryFile> }>());
export const updateCategoryFileFailed = createAction('[CategoryFile/API] Update Category File Failed', props<{ error: any }>());

export const deleteCategoryFileRequest = createAction('[CategoryFile/API] Delete CategoryFile Request', props<{ id: string }>());
export const deleteCategoryFile = createAction('[CategoryFile/API] Delete CategoryFile', props<{ id: string }>());
export const deleteCategoryFileFailed = createAction('[CategoryFile/API] Delete Category File Failed', props<{ error: any }>());

export const clearCategoryFiles = createAction('[CategoryFile/API] Clear Category Files');
export const selectCategoryFile = createAction('[CategoryFile/API] Select Category File', props<{ id: string }>());
