import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '../../../interfaces/common/users';

export interface UserState extends EntityState<User> {
  isLoad: boolean;
  isLoading?: boolean;
  error?: any;
  selectedUserId: string | null;
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: selectUserId,
  sortComparer: sortByName,
});

export const initialUserState: UserState = userAdapter.getInitialState({
  isLoad: false,
  isLoading: false,
  error: null,
  selectedUserId: null,
});

function selectUserId(user: User): string {
  return user.id;
}

function sortByName(userA: User, userB: User): number {
  return userA.login.localeCompare(userB.login);
}
