import { createSelector } from '@ngrx/store';
import * as fromFeature from '../app-store.reducers';
import { roleAdapter, RoleState } from '../state/role.state';

export const selectRoleState = createSelector(fromFeature.getAppState, (state: fromFeature.PageState) => state.roles);

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal } = roleAdapter.getSelectors();

const getSelectedRoleId = (state: RoleState) => state.selectedRoleId;
const getError = (state: RoleState): any => state.error;
const getIsLoading = (state: RoleState): boolean => state.isLoading;

export const selectAllRoles = createSelector(selectRoleState, selectAll);
export const selectRoleIds = createSelector(selectRoleState, selectIds);
export const selectRoleEntities = createSelector(selectRoleState, selectEntities);
export const selectRoleTotal = createSelector(selectRoleState, selectTotal);
export const selectCurrentRoleId = createSelector(selectRoleState, getSelectedRoleId);

export const selectCurrentRole = createSelector(selectRoleEntities, selectCurrentRoleId, (RoleEntities, RoleId) => RoleEntities[RoleId]);
export const selectRoleError = createSelector(selectRoleState, getError);
export const selectRoleIsLoading = createSelector(selectRoleState, getIsLoading);
export const selectRoleById = (id: string) => createSelector(selectRoleState, RolesState => RolesState.entities[id]);
