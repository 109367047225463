import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';

import * as RoleAction from '../actions/role.actions';
import { RoleApi } from '../../../backend/common/api/role.api';
import { Role } from '../../../interfaces/common/role';

@Injectable()
export class RoleEffects {
  constructor(
    private toasterService: NbToastrService,
    private actions$: Actions,
    private api: RoleApi) {}

  private handleError(operation: string, entity: Role, error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(operation, entity, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        operation,
        entity,
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    this.toasterService.danger('Error al procesar el registro. Vuelva intentarlo nuevamente. Si el error persiste consulte al administrador.');
  }

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.loadRolesRequest),
      exhaustMap(() =>
        this.api.getAll().pipe(
          map(roles => RoleAction.loadRoles({ roles })),
          catchError(({ error }) => of(RoleAction.loadRolesFailed({ error }))),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.getRoleRequest),
      exhaustMap(action =>
        this.api.getById(action.id).pipe(
          map(role => RoleAction.getRole({ role })),
          catchError(({ error }) => of(RoleAction.getRoleFailed({ error }))),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.addRoleRequest),
      exhaustMap(action =>
        this.api.add(action.role).pipe(
          map(role => {
            this.toasterService.success('Registro creado exitosamente', 'Creación de role');
            return RoleAction.addRole({ role });
          }),
          catchError(({ error }) => {
            this.handleError('Add Role', action.role, error);
            return of(RoleAction.addRoleFailed({ error }));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.updateRoleRequest),
      exhaustMap(action =>
        this.api.update(action.role).pipe(
          map(role => {
            this.toasterService.success('Registro actualizado exitosamente', 'Actualización de role');
            return RoleAction.updateRole({ role });
          }),
          catchError(({ error }) => {
            this.handleError('Update Role', action.role, error);
            return of(RoleAction.updateRoleFailed({ error }));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.deleteRoleRequest),
      exhaustMap(action =>
        this.api.delete(action.id).pipe(
          map(role => {
            this.toasterService.success('Registro eliminado exitosamente', 'Eliminación de role');
            return RoleAction.deleteRole({ id: action.id });
          }),
          catchError(({ error }) => {
            this.handleError('Delete Role ' + action.id, undefined, error);
            return of(RoleAction.deleteRoleFailed({ error }));
          }),
        ),
      ),
    ),
  );
}
