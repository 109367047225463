import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import * as AuthAction from './auth.actions';
import { defer, of } from 'rxjs';
import { UsersApi } from '../../backend/common/api/users.api';
import { NbAuthService } from '@nebular/auth';
import { switchMap, exhaustMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private api: UsersApi,
    private authService: NbAuthService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.Login),
    ),
    { dispatch: false },
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.Logout),
      tap((action) => {
        this.router.navigateByUrl(action.redirect);
      }),
    ),
    { dispatch: false },
  );

  @Effect()
  init$ = defer(() => {
    return this.authService.isAuthenticated()
    .pipe(
      switchMap(authenticated => {
        return authenticated ? this.api.getCurrent() : of(null);
      }),
      exhaustMap(user => {
        if (user && !user.setting) {
          user.setting = {};
        }

        if (user) {
          return of(AuthAction.Login({user: user}));
        } else {
          return <any> of(AuthAction.Logout({ redirect: '/login' }));
        }
      }),
    );
  });
}
