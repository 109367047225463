import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as Actions from '../actions/category.actions';
// reducers
import { CategoryState } from '../state/category.state';
import { Category } from '../../../interfaces/common/category';
import * as fromSelectors from '../selectors/category.selectors';

@Injectable()
export class CategoryStore {
  constructor(private store: Store<CategoryState>) {}

  serverList() {
    this.store.dispatch(Actions.loadCategoriesRequest());
  }

  add(category: Category) {
    this.store.dispatch(Actions.addCategoryRequest({ category }));
  }

  update(category: Category) {
    this.store.dispatch(Actions.updateCategoryRequest({ category }));
  }

  delete(id: string) {
    this.store.dispatch(Actions.deleteCategoryRequest({ id }));
  }

  getError() {
    return this.store.select(fromSelectors.selectCategoryError);
  }

  getIsLoading() {
    return this.store.select(fromSelectors.selectCategoryIsLoading);
  }

  getSelected() {
    return this.store.select(fromSelectors.selectCurrentCategory);
  }

  getByID(id: string) {
    return this.store.select(fromSelectors.selectCategoryById(id));
  }

  getAllCategory() {
    return this.store.pipe(select(fromSelectors.selectAllCategories));
  }
}
