import { UsersApi } from './users.api';

import { HttpService } from './infrastructure/http.service';
import { GenericApi } from './infrastructure/generic.api';
import { GenericHttpService } from './infrastructure/generic.http.service';

import { UserData } from '../../../interfaces/common/users';
import { UsersService } from '../services/users.service';
import { RoleApi } from './role.api';
import { CategoryApi } from './category.api';
import { CategoryFileApi } from './category-files.api';

export const api: any[] = [
  UsersApi,
  RoleApi,
  CategoryApi,
  CategoryFileApi,

  HttpService,
  GenericApi,
  GenericHttpService,
];

export const services: any[] = [
  { provide: UserData, useClass: UsersService },
];
