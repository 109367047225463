import { CategoryFile } from './../../../../@core/interfaces/common/category-file';
import {Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import { NbWindowRef, NbToastrService } from '@nebular/theme';
import { CategoryFileStore } from '../../../../@core/root-store/app-store/store/category-file.store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbAccessChecker } from '@nebular/security';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { FileUploaderComponent } from '../../../../@components/upload-file/upload-file.component';

export enum CategoryFileFormMode {
  VIEW = 'View',
  EDIT = 'Edit',
}

@Component({
  selector: 'ngx-category-file-edit',
  templateUrl: './category-file-edit.component.html',
  styleUrls: ['./category-file-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFileEditFormComponent {
  @Input() categoryFile: CategoryFile;
  @Input() isAdd: boolean;
  categoryForm: FormGroup;
  mode: CategoryFileFormMode;
  private token: string;
  private loading: boolean = false;
  private percentage: number = 0;
  nameFile: string;

  @ViewChild('fileUploader', {static: false}) fileUploader: FileUploaderComponent;

  setViewMode(viewMode: CategoryFileFormMode) {
    this.mode = viewMode;
  }

  uploadConfig: any;

  constructor(
    private authService: NbAuthService,
    public windowRef: NbWindowRef,
    private accessChecker: NbAccessChecker,
    private store: CategoryFileStore,
    private fb: FormBuilder,
    private toasterService: NbToastrService,
    private changeDetector: ChangeDetectorRef) {
    // This line is fix to husky check
    if (this.loading && this.percentage === 0) this.loading = false;
    this.initUserForm();
    this.loadUserData();
    this.accessChecker.isGranted('edit', 'category').pipe(map(authorize => {
      this.setViewMode(authorize ? CategoryFileFormMode.EDIT : CategoryFileFormMode.VIEW);
      return authorize;
    }));
    this.authService.getToken()
      .subscribe((token: NbAuthToken) => {
        if (token.isValid()) {
          this.token = token.getValue();
          const fileId = this.categoryFile.fileId === undefined || this.categoryFile.fileId === null ? 0 : this.categoryFile.fileId;
          const categoryId = this.categoryFile.id === undefined || this.categoryFile.id === null ? 'null' : this.categoryFile.id;

          this.uploadConfig = {
            id: '-fileId',
            theme: 'attachPin',
            maxSize: 1024,
            // tslint:disable-next-line: max-line-length
            formatsAllowed: environment.extensions,
            onlyAttach: true,
            uploadAPI: {
              url: `${ environment.apiUrl }/categoryFile/uploadFile/${ fileId }/${ categoryId }/${ this.categoryFile.parentId }`,
              headers: {
                // "Content-Type" : "application/json;charset=UTF-8",
                'Authorization': 'Bearer ' + this.token,
              },
            },
            multiple: false,
            replaceTexts: {
              selectFileBtn: 'Seleccionar archivo',
              resetBtn: 'Limpiar',
              uploadBtn: 'Cargar',
              dragNDropBox: 'Arrastrar y soltar',
              attachPinBtn: 'Agregar archivos...',
              afterUploadMsg_success: '¡Carga exitosa!',
              afterUploadMsg_error: '¡Carga fallida!',
            },
          };
        }
      });
  }

  initUserForm() {
    this.categoryForm = this.fb.group({
        id: this.fb.control(''),
        parentId: this.fb.control(''),
        name: this.fb.control('', [Validators.required]),
        description: this.fb.control(''),
        pathFile: this.fb.control(''),
        extension: this.fb.control(''),
        fileId: this.fb.control(0),
        trash: this.fb.control(false),
        readonly: this.fb.control(false),
      });
  }

  loadUserData() {
    if (!this.categoryFile) {
      if (this.windowRef && this.windowRef.config.context) {
        this.categoryFile = this.windowRef.config.context['category'];
        this.isAdd = this.windowRef.config.context['isAdd'];
        this.nameFile = decodeURIComponent(this.categoryFile.pathFile).split('/').reverse()[0];
      }
    }

    this.categoryForm.patchValue(this.categoryFile);

    this.windowRef.onClose.subscribe(() => {
      this.fileUploader.cancelUpload();
    });
  }

  get canEdit(): boolean {
    return this.mode !== CategoryFileFormMode.EDIT;
  }

  get hasFile(): boolean {
    return this.categoryFile.pathFile && this.categoryFile.pathFile.trim() !== '';
  }

  convertToCategory(value: any): CategoryFile {
    const category: CategoryFile = value;
    return category;
  }

  fileUpload(xhr: XMLHttpRequest) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const fileUploaded = JSON.parse(xhr.response) as CategoryFile;
        const category: CategoryFile = {
          ...this.convertToCategory(this.categoryForm.value),
          fileId: fileUploaded.fileId,
          pathFile: fileUploaded.pathFile,
          extension: fileUploaded.extension,
        };

        if (category.id === undefined || category.id === null || category.id.trim() === '') {
          category.id = fileUploaded.id;
          category.parentId = fileUploaded.parentId;
        }
        // console.log(this.mode);
        // if (this.mode === CategoryFormMode.EDIT) {
          const categoryId = category.id === undefined || category.id === null ? 'null' : category.id;

          this.categoryFile = category;
          // tslint:disable-next-line: max-line-length
          this.uploadConfig.uploadAPI.url = `${ environment.apiUrl }/categoryFile/uploadFile/${ fileUploaded.fileId }/${ categoryId }/${ category.parentId }`;
          this.categoryForm.patchValue(category);

          // if (this.isAdd) this.store.add(category);
          // else this.store.update(category);

          this.saveObject();
        // }
      } else if (xhr.status === 400) {
        this.toasterService.danger('Error al cargar el archivo. Vuelva intentarlo nuevamente. Si el error persiste consulte al administrador.');
      }
    }
  }

  percentageChange(percent: number) {
    this.percentage = percent;

    this.changeDetector.detectChanges();
  }

  upsert() {
    if (this.fileUploader.selectedFiles.length !== 0) {
      this.loading = true;
      this.fileUploader.uploadFiles();
    } else if (this.hasFile) {
      this.loading = true;
      this.saveObject();
    }
  }

  saveObject() {
    // const button: HTMLElement = document.getElementById(`upload-file-button${ this.uploadConfig.id }`) as HTMLElement;
    // button.click();
    const category: CategoryFile = this.convertToCategory(this.categoryForm.value);
    // console.log(this.mode);
    // if (this.mode === CategoryFormMode.EDIT) {
    if (this.isAdd) this.store.add(category);
    else this.store.update(category);
    // }
    this.loading = false;
    this.close();
  }

  close() {
    this.windowRef.close();
  }
}
