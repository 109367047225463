import { Component, Input, NgZone, OnDestroy, OnChanges, SimpleChanges, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

@Component({
  selector: 'ngx-doc-viewer',
  templateUrl: './document-viewer.component.html',
  styles: [`
    :host {
      display: block;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    .disabledDownload {
      position: absolute;
      width: 44px;
      height: 42px;
      top: 27px;
      right: 32px;
      padding: 0;
      z-index: 1000;
      background-color: #e8e8e8;
      cursor: not-allowed;
      opacity: 0.65;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .toolbarHide {
      display: none;
    }
  `],
})
export class NgxDocViewerComponent implements OnChanges, OnDestroy {
  public fullUrl: SafeResourceUrl;
  public safeStyle: SafeStyle;
  public textToSearch: string;
  private checkIFrameSubscription: Subscription;
  private configuredViewer = 'google';
  private _isLoadingPdf: boolean = false;
  private _pdfLoadFailed: boolean = false;
  private _pdfSearchFailed: boolean = false;
  private _zoomLevel: number = 1;

  constructor(private domSanitizer: DomSanitizer, private ngZone: NgZone) { }

  @Output() loaded: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean;
  @Input() url: string;
  @Input() googleCheckInterval = 3000;
  @Input() set viewer(viewer: string) {
    const v = viewer.toLowerCase().trim();
    if (v !== 'google' && v !== 'office' && v !== 'pdf') {
      console.error(`Unsupported viewer: '${viewer}'. Supported viewers: google, office, pdf`);
    }
    this.configuredViewer = v;
  }

  @ViewChild(PdfViewerComponent, {static: false}) private _pdfViewerComponent: PdfViewerComponent;

  get viewer(): string {
    return this.configuredViewer;
  }

  get isLoadingPdf(): boolean {
    return this._isLoadingPdf;
  }

  get pdfLoadFailed(): boolean {
    return this._pdfLoadFailed;
  }

  get pdfSearchFailed(): boolean {
    return this._pdfSearchFailed;
  }

  get zoomLevel(): number{
    return this._zoomLevel;
  }

  ngOnDestroy(): void {
    if (this.checkIFrameSubscription) {
      this.checkIFrameSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.url && changes.url.currentValue !== changes.url.previousValue) ||
      changes.viewer && changes.viewer.currentValue !== changes.viewer.previousValue) {

      switch (this.configuredViewer) {
        case 'pdf':
          this._isLoadingPdf = true;
          this.fullUrl = this.url;
          break;

        case 'google':
          this.fullUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${this.url.indexOf('/') ? encodeURIComponent(decodeURIComponent(this.url)) : this.url}&embedded=true`);
          break;

        case 'office':
          this.fullUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${this.url.indexOf('/') ? encodeURIComponent(decodeURIComponent(this.url)) : this.url}`);
          break;

        default:
          throw new Error(`Unsupported viewer: '${this.configuredViewer}'. Supported viewers: google, office`);

      }

      if (this.configuredViewer !== 'google')
        return;

        this.ngZone.runOutsideAngular(() => {
          let iframe = document.querySelector('iframe');
          this.checkIFrame(iframe);
          // if it's not loaded after the googleIntervalCheck, then open load again.
          this.checkIFrameSubscription = interval(this.googleCheckInterval)
            .pipe(
              take(Math.round(this.googleCheckInterval === 0 ? 0 : 20000 / this.googleCheckInterval)))
            .subscribe(() => {
              if (iframe == null) {
                iframe = document.querySelector('iframe');
                this.checkIFrame(iframe);
              }
              this.reloadIFrame(iframe);
            });
        });
    }
  }

  increaseZoom(): void {
    this._zoomLevel += 0.100
  }

  decreaseZoom(): void {
    if (this._zoomLevel <= 1)
      return;

    this._zoomLevel -= 0.100
  }

  onPdfLoaded(): void {
    this._isLoadingPdf = false;
  }

  onPdfLoadFailed(): void {
    this._pdfLoadFailed = true;
    this.onPdfLoaded();
  }

  checkIFrame(iframe: HTMLIFrameElement) {
    if (iframe) {
      iframe.onload = () => {
        this.loaded.emit(null);
        if (this.checkIFrameSubscription) {
          this.checkIFrameSubscription.unsubscribe();
        }
      };
    }
  }

  reloadIFrame(iframe: HTMLIFrameElement) {
    if (iframe) {
      // console.log('reloading..');
      iframe.src = iframe.src;
    }
  }

  checkIsReadonly() {
    const className = 'toolbarHide';
    const toolbar = document.getElementsByClassName('disabledDownload')[0];
    if (this.readonly) {
      if (this.hasClass(toolbar, className)) this.removeClass(toolbar, className);
    } else {
      if (!this.hasClass(toolbar, className)) this.addClass(toolbar, className);
    }
  }

  hasClass(el: any, className: string): boolean {
    if (el.classList)
      return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  }

  addClass(el: any, className: string) {
    if (el.classList)
      el.classList.add(className);
    else if (!this.hasClass(el, className))
      el.className += ' ' + className;
  }

  removeClass(el: any, className: string) {
    if (el.classList)
      el.classList.remove(className);
    else if (this.hasClass(el, className)) {
      const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  }

  searchPreviousInPdf(): void {
    this.searchInPdf(SearchTextDirecton.Previous);
  }

  searchNextInPdf(): void {
    this.searchInPdf(SearchTextDirecton.Next);
  }

  searchInPdf(searchTextDirection: SearchTextDirecton = SearchTextDirecton.None): void {
    let findPrevious: boolean;
    let isFirstSearch: boolean;

    switch (searchTextDirection) {
      case SearchTextDirecton.Previous:
        findPrevious = true;
        isFirstSearch = false;
        break;

      case SearchTextDirecton.Next:
        findPrevious = false;
        isFirstSearch = false;
        break;

      default:
        findPrevious = undefined;
        isFirstSearch = true;
        break;
    }

    this._pdfViewerComponent.pdfFindController.executeCommand(isFirstSearch ? 'find' : 'findagain', {
      caseSensitive: false,
      findPrevious: findPrevious,
      highlightAll: true,
      phraseSearch: true,
      query: this.textToSearch
    });
  }
}

enum SearchTextDirecton {
  None,
  Previous,
  Next
}
