import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';

import * as API from './api';
import * as fromStores from '../../root-store/app-store/store';
import { AuthStore } from '../../root-store/auth-store/auth.store';

@NgModule({
  imports: [CommonModule, NbAuthModule],
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CommonBackendModule,
      providers: [
        ...API.api,
        ...API.services,
        ...fromStores.stores,
        AuthStore,
      ],
    };
  }
}
