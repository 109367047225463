import { createAction, props } from '@ngrx/store';
import { User } from '../../../interfaces/common/users';
import { UpdateStr } from '@ngrx/entity/src/models';

export const getUserRequest = createAction('[User/API] Get User Request', props<{ id: string }>());
export const getUser = createAction('[User/API] Get User', props<{ user: User }>());
export const getUserFailed = createAction('[User/API] Get User Failed', props<{ error: any }>());

export const updateCurrentUserRequest = createAction('[User/API] Update Current User Request', props<{ user: User }>());
export const updateCurrentUser = createAction('[User/API] Update Current User', props<{ user: UpdateStr<User> }>());
export const updateCurrentUserFailed = createAction('[User/API] Update Current User Failed', props<{ error: any }>());

export const updateUserRoleRequest = createAction('[User/API] Update Role by User Request', props<{ user: string, role: string }>());
export const updateUserRole = createAction('[User/API] Update  Role by User', props<{ user: UpdateStr<User> }>());
export const updateUserRoleFailed = createAction('[User/API] Update  Role by User Failed', props<{ error: any }>());

export const loadUsersRequest = createAction('[User/API] Load Users Request');
export const loadUsers = createAction('[User/API] Load Users', props<{ users: User[] }>());
export const loadUsersFailed = createAction('[User/API] Load Users Failed', props<{ error: any }>());

export const addUserRequest = createAction('[User/API] Add User Request', props<{ user: User }>());
export const addUser = createAction('[User/API] Add User', props<{ user: User }>());
export const addUserFailed = createAction('[User/API] Add User Failed', props<{ error: any }>());

export const updateUserRequest = createAction('[User/API] Update User Request', props<{ user: User }>());
export const updateUser = createAction('[User/API] Update User', props<{ user: UpdateStr<User> }>());
export const updateUserFailed = createAction('[User/API] Update User Failed', props<{ error: any }>());

export const deleteUserRequest = createAction('[User/API] Delete User Request', props<{ id: string }>());
export const deleteUser = createAction('[User/API] Delete User', props<{ id: string }>());
export const deleteUserFailed = createAction('[User/API] Delete User Failed', props<{ error: any }>());

export const clearUsers = createAction('[User/API] Clear Users');
export const selectUser = createAction('[User/API] Select User', props<{ id: string }>());

// export const upsertUser = createAction('[User/API] Upsert User', props<{ user: User }>());
// export const addUsers = createAction('[User/API] Add Users', props<{ users: User[] }>());
// export const upsertUsers = createAction('[User/API] Upsert Users', props<{ users: User[] }>());
// export const updateUsers = createAction('[User/API] Update Users', props<{ users: Update<User>[] }>());
// export const mapUsers = createAction('[User/API] Map Users', props<{ entityMap: EntityMap<User> }>());
// export const deleteUsers = createAction('[User/API] Delete Users', props<{ ids: string[] }>());
// export const deleteUsersByPredicate = createAction('[User/API] Delete Users By Predicate', props<{ predicate: Predicate<User> }>());
