import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericHttpService } from './infrastructure/generic.http.service';
import { CategoryFile } from '../../../interfaces/common/category-file';

@Injectable()
export class CategoryFileApi extends GenericHttpService<CategoryFile> {
  private readonly apiController: string = 'categoryFile';

  public getAll(): Observable<any> {
    return this.get(this.apiController);
  }

  public getById(id: string): Observable<any> {
    return this.getSingle(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.post(this.apiController, item);
  }

  update(item: any): Observable<any> {
    return this.put(`${this.apiController}/${item.id}`, item);
  }

  delete(id: string): Observable<boolean> {
    return this.remove(`${this.apiController}/${id}`);
  }

  public download(id: string): any {
    return  this.getAny(`file/${id}`, { responseType: 'arraybuffer' });
  }
}
