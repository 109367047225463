import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef, ComponentRef, ElementRef } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { NbWindowService } from '@nebular/theme';
import { ActivatedRoute, Params } from '@angular/router';

import { CategoryFile } from '../../../@core/interfaces/common/category-file';
import { CategoryStore } from '../../../@core/root-store/app-store/store/category.store';
import { CategoryFileStore } from '../../../@core/root-store/app-store/store/category-file.store';
import { CategoryFileEditFormComponent } from './edit-form/category-file-edit.component';
import { Category } from '../../../@core/interfaces/common/category';
import { AuthStore } from '../../../@core/root-store/auth-store/auth.store';
import { User } from '../../../@core/interfaces/common/users';
import { TreeNode } from '../../../@components/ng-js-tree/ng-js-tree.component';
import { CategoryEditFormComponent } from './edit-form/category-edit.component';
import { CategoryFileApi } from '../../../@core/backend/common/api/category-files.api';
import { ROLES } from '../../../@auth/roles';
import { PreviewService } from '../../../@core/utils/preview.service';

export enum SortingOrder {
  ASCENDING,
  DESCENDING
};

@Component({
  selector: 'ngx-category-file',
  templateUrl: './category-file.component.html',
  styleUrls: ['./category-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFileComponent implements OnInit, OnDestroy {
  @ViewChild('tmpDeleteFolder', { static: true }) tmpDeleteFolder: TemplateRef<any>;
  @ViewChild('tmpDeleteFile', { static: true }) tmpDeleteFile: TemplateRef<any>;
  @ViewChild('previuwDocument', { static: true }) previuwDocument: TemplateRef<any>;
  @ViewChild('jstree', { static: true }) jstree;
  protected readonly unsubscribe$ = new Subject<void>();
  public filesObserver: Observable<CategoryFile[]>;
  public foldersObserver: Observable<Category[]>;
  private files: CategoryFile[] = [];
  private folders: Category[] = [];
  private _categoryFileId: string;
  user: User;
  searchText: string;
  dataTree: TreeNode[];
  windowRef;
  categoriesSortingOrder: SortingOrder = SortingOrder.ASCENDING;

  get categoriesAreSortedAscending(): boolean {
    return this.categoriesSortingOrder === SortingOrder.ASCENDING;
  }
  constructor(
    private storeFiles: CategoryFileStore,
    private storeCategories: CategoryStore,
    private windowService: NbWindowService,
    private userAuthService: AuthStore,
    private cd: ChangeDetectorRef,
    private apiFile: CategoryFileApi,
    private _previewService: PreviewService,
    private _route: ActivatedRoute
  ) {
    this._route.queryParams
      .subscribe((params: Params) => {
        if (params.categoryFileId === undefined ||
          params.categoryFileId === null ||
          params.categoryFileId === '') {
            return;
        }

        this._categoryFileId = params.categoryFileId;
      }
    );
  }

  ngOnInit(): void {
    this.userAuthService.getAuthenticatedUser()
      .subscribe(user => {
        this.user = user;
      });
    this.storeFiles.serverList();
    this.storeCategories.serverList();

    this.filesObserver = this.storeFiles.getAllCategoryFile();
    this.foldersObserver = this.storeCategories.getAllCategory();
    const observer = merge(
      this.filesObserver.pipe(
        takeUntil(this.unsubscribe$),
        switchMap(res => {
          if (res.length > 0) this.files = res;
          return res;
        }),
      ),
      this.foldersObserver.pipe(
        takeUntil(this.unsubscribe$),
        switchMap(res => {
          if (res.length > 0) {
            this.folders = [];

            res.forEach(result => {
              this.folders.push({
                id: result.id,
                parentId: result.parentId,
                name: result.name,
                description: result.description,
                createdBy: result.createdBy,
                pathFolder: result.pathFolder,
                order: result.order,
                sortingOrderForName: SortingOrder.ASCENDING,
                sortingOrderForDate: SortingOrder.ASCENDING
              })
            });
          }

          return res;
        }),
      ),
    );

    observer.subscribe(() => {
      this.dataTree = this.ToHierarchical(this.folders, this.files);
      this.cd.markForCheck();
    });

    if (this._categoryFileId === undefined ||
      this._categoryFileId === null ||
      this._categoryFileId === ''){
        return;
    }

    this.storeFiles.getByID(this._categoryFileId).subscribe((response: CategoryFile) => {
      if (response === null || response === undefined)
        return;

      this.previuw(response);
    });
  }

  sortRootNodes(): void {
    this.dataTree = this.dataTree.sort((a, b) => (this.categoriesSortingOrder === SortingOrder.DESCENDING
      ? a.text.localeCompare(b.text)
      : b.text.localeCompare(a.text)
    ));
    this.categoriesSortingOrder = this.categoriesSortingOrder === SortingOrder.ASCENDING
      ? SortingOrder.DESCENDING
      : SortingOrder.ASCENDING;
  }

  sortByName(first: TreeNode, second: TreeNode, sortingOrder: SortingOrder): number {
    return sortingOrder === SortingOrder.DESCENDING
      ? second.text.localeCompare(first.text)
      : first.text.localeCompare(second.text);
  };

  sortByDate(first: TreeNode, second: TreeNode, sortingOrder: SortingOrder): number {
    return sortingOrder === SortingOrder.DESCENDING
      ? new Date(second.data.updatedAt).valueOf() - new Date(first.data.updatedAt).valueOf()
      : new Date(first.data.updatedAt).valueOf() - new Date(second.data.updatedAt).valueOf()
  };

  sortNode(toSort: TreeNode, isSortingByName: boolean): void {
    if (toSort.children === null || toSort.children === undefined || toSort.children.length === 0)
      return;

    if (isSortingByName) {
      toSort.data.sortingOrderForName = (toSort.data.sortingOrderForName === null ||
        toSort.data.sortingOrderForName === undefined ||
        toSort.data.sortingOrderForName === SortingOrder.DESCENDING)
          ? SortingOrder.ASCENDING
          : SortingOrder.DESCENDING;
    } else {
      toSort.data.sortingOrderForDate = (toSort.data.sortingOrderForDate === null ||
        toSort.data.sortingOrderForDate === undefined ||
        toSort.data.sortingOrderForDate === SortingOrder.DESCENDING)
          ? SortingOrder.ASCENDING
          : SortingOrder.DESCENDING;
    }


    toSort.childrenToSearch = toSort.childrenToSearch
      .filter(c => c.type === 'folder' || c.type === 'default')
        .sort((a, b) => this.sortByName(a, b, toSort.data.sortingOrderForName))
      .concat(toSort.childrenToSearch
        .filter(c => c.type !== 'folder' && c.type !== 'default')
          .sort((a, b) => isSortingByName
            ? this.sortByName(a, b, toSort.data.sortingOrderForName)
            : this.sortByDate(a, b, toSort.data.sortingOrderForDate)));

    let nodeWasReplaced: boolean = false;

    this.dataTree.forEach((currentRootNode: TreeNode) => {
      if (nodeWasReplaced)
        return;

      nodeWasReplaced = this.replaceChildNodes(currentRootNode, toSort);
    });

    this.jstree.forceTreeRefresh();
  }

  private replaceChildNodes(nodeWithChildrenToReplace: TreeNode, nodeWithNewChildren: TreeNode): boolean {
    if (nodeWithChildrenToReplace.id === nodeWithNewChildren.id) {
      const unsortedChildren: TreeNode[] = [...nodeWithChildrenToReplace.children];
      nodeWithChildrenToReplace.children = [];

      if (nodeWithChildrenToReplace.data.sortingOrderForName !== null &&
        nodeWithChildrenToReplace.data.sortingOrderForName !== undefined &&
        nodeWithNewChildren.data.sortingOrderForName !== null &&
        nodeWithNewChildren.data.sortingOrderForName !== undefined) {
          nodeWithChildrenToReplace.data.sortingOrderForName = nodeWithNewChildren.data.sortingOrderForName;
      }

      if (nodeWithChildrenToReplace.data.sortingOrderForDate !== null &&
        nodeWithChildrenToReplace.data.sortingOrderForDate !== undefined &&
        nodeWithNewChildren.data.sortingOrderForDate !== null &&
        nodeWithNewChildren.data.sortingOrderForDate !== undefined) {
          nodeWithChildrenToReplace.data.sortingOrderForDate = nodeWithNewChildren.data.sortingOrderForDate;
      }

      nodeWithNewChildren.childrenToSearch.forEach((currentChildSearch: TreeNode) => {
        nodeWithChildrenToReplace.children.push(unsortedChildren.find(c => c.id === currentChildSearch.id));
      });

      return true;
    }

    const currentNodeHasChildren: boolean = nodeWithChildrenToReplace.children !== null &&
      nodeWithChildrenToReplace.children !== undefined &&
      nodeWithChildrenToReplace.children.length > 0;

    if (!currentNodeHasChildren)
      return false;

    nodeWithChildrenToReplace.children.forEach((currentRootNode: TreeNode) => {
      return this.replaceChildNodes(currentRootNode, nodeWithNewChildren);
    });
  }

  private ToHierarchical(categories: Category[], files: CategoryFile[]): TreeNode[] {
    const roots: TreeNode[] = [];

    if (categories.length > 0) {
      const categoryById = Object.assign({}, ...categories.map(s => (
        { [s.id]: {
            id: s.id,
            text: s.name,
            data: s,
            children: [],
          } as TreeNode })));
      const fileById = Object.assign({}, ...files.map(s => (
        { [s.id]: {
          id: s.id,
          text: this.getTemplateFile(s),
          data: s,
          type: this.getTypeFile(s),
        } as TreeNode })));

      categories.forEach((node) => {
        if (node.parentId !== undefined && node.parentId !== null) {
          // if you have dangling branches check that map[node.parentId] exists
          categoryById[node.parentId].children.push(categoryById[node.id]);
        } else {
          roots.push({...categoryById[node.id], type: 'root'});
        }
      });

      files.forEach((node) => {
        if (!categoryById[node.parentId]) categoryById[node.parentId].children = [];
        categoryById[node.parentId].children.push(fileById[node.id]);
      });
    }

    return roots;
  }

  private getTemplateFile(file: CategoryFile): string {
    const dateConvert = file.updatedAt ? new Date(file.updatedAt) : new Date(file.createdAt);
    const dateFormat = dateConvert.toLocaleDateString();
    return  '<div class="ngTreeDiv"><table><tr>' +
            '<td>' +
              '<div class="TreeTooltip" title="' + file.name + '">' +
                file.name +
              '</div>' +
            '</td>' +
            '<td>' +
              '<div class="TreeTooltip" title="' + file.description + '">' +
                file.description +
              '</div>' +
            '</td>' +
            '<td style="width: 82px; text-align: center;">' +
              '<div class="TreeTooltip" title="' + file.extension + '">' +
                file.extension +
              '</div>' +
            '</td>' +
            '<td style="width: 202px; text-align: center;">' +
              '<div class="TreeTooltip" title="' + dateFormat + '">' +
                dateFormat +
              '</div>' +
            '</td>' +
            '</tr></table></div>';
  }

  private getTypeFile(file: CategoryFile): string {
    let extension = '';
    switch (file.extension.toLowerCase()) {
      // PDF
      case 'pdf': extension = 'pdf'; break;
      // Adobe Files
      case 'psd': extension = 'adobe'; break;
      case 'ps': extension = 'adobe'; break;
      case 'ai': extension = 'adobe'; break;
      // CSV
      case 'csv': extension = 'csv'; break;
      // File text
      case 'txt': extension = 'file'; break;
      case 'rtf': extension = 'file'; break;
      // ZIP package
      case 'rar': extension = 'archive'; break;
      case 'zip': extension = 'archive'; break;
      case '7z': extension = 'archive'; break;
      // Extension by Files Excel
      case 'xlc': extension = 'excel'; break;
      case 'xls': extension = 'excel'; break;
      case 'xlsx': extension = 'excel'; break;
      case 'xlsm': extension = 'excel'; break;
      case 'xlsb': extension = 'excel'; break;
      case 'xltx': extension = 'excel'; break;
      case 'xltm': extension = 'excel'; break;
      case 'xlt': extension = 'excel'; break;
      case 'xla': extension = 'excel'; break;
      case 'xlw': extension = 'excel'; break;
      case 'xlr': extension = 'excel'; break;
      case 'xlam': extension = 'excel'; break;
      // Extension by Files Word
      case 'docx': extension = 'word'; break;
      case 'docm': extension = 'word'; break;
      case 'dotx': extension = 'word'; break;
      case 'dotm': extension = 'word'; break;
      case 'doc': extension = 'word'; break;
      case 'wps': extension = 'word'; break;
      case 'dot': extension = 'word'; break;
      // Extension by Files Power point
      case 'pot': extension = 'powerpoint'; break;
      case 'potm': extension = 'powerpoint'; break;
      case 'potx': extension = 'powerpoint'; break;
      case 'ppa': extension = 'powerpoint'; break;
      case 'ppam': extension = 'powerpoint'; break;
      case 'pps': extension = 'powerpoint'; break;
      case 'ppsm': extension = 'powerpoint'; break;
      case 'ppsx': extension = 'powerpoint'; break;
      case 'ppt': extension = 'powerpoint'; break;
      case 'pptm': extension = 'powerpoint'; break;
      case 'pptx': extension = 'powerpoint'; break;
      // Extension by Files images
      case 'jpg': extension = 'image'; break;
      case 'gif': extension = 'image'; break;
      case 'jpeg': extension = 'image'; break;
      case 'png': extension = 'image'; break;
      case 'bmp': extension = 'image'; break;
      // Extension by Files video
      case 'mp4': extension = 'video'; break;
      case 'avi': extension = 'video'; break;
      case 'wmv': extension = 'video'; break;
      case 'mov': extension = 'video'; break;
      // Extension by Files audio
      case 'mp3': extension = 'audio'; break;
      case 'wav': extension = 'audio'; break;
      default: extension = 'unknown';
    }
    return extension;
  }

  onAction(node: any) {
    if (node.type === 'folder') {
      if (node.action === 'create' || node.action === 'edit') this.upsertFolder(node.node.data as Category, node.action === 'create');
      if (node.action === 'delete') this.confirmDeleteForlder(node.node.data as Category);
      if (node.action === 'dnd') this.confirmUpsertFolder(node.node.data as Category);
      if (node.action === 'sort-by-name') this.sortNode(node.node as TreeNode, true);
      if (node.action === 'sort-by-date') this.sortNode(node.node as TreeNode, false);
    } else if (node.type === 'file') {
      if (node.action === 'create' || node.action === 'edit') this.upsertFile(node.node.data as CategoryFile, node.action === 'create');
      if (node.action === 'delete') this.confirmDeleteFile(node.node.data as CategoryFile);
      if (node.action === 'previuw') this.previuw(node.node.data as CategoryFile);
      if (node.action === 'download') this.download(node.node.data as CategoryFile);
      if (node.action === 'dnd') this.confirmUpsertFile(node.node.data as CategoryFile);
    }
  }

  upsertFolder(row: Category, isAdd: boolean) {
    const value = {} as Category;
    if (isAdd && row) value.parentId = row.id;
    this.windowService.open(CategoryEditFormComponent, { title: isAdd ? 'Agregar' : 'Editar', context: { category: isAdd ? value : row, isAdd } });
  }

  confirmUpsertFolder(category: Category) {
    this.storeCategories.update(category);
  }

  upsertFile(row: CategoryFile, isAdd: boolean) {
    const value = {} as CategoryFile;
    if (isAdd && row) value.parentId = row.id;
    this.windowService.open(CategoryFileEditFormComponent, {
      title: isAdd ? 'Agregar' : 'Editar',
      context: { category: isAdd ? value : row, isAdd },
    });
  }

  confirmUpsertFile(file: CategoryFile) {
    this.storeFiles.update(file);
  }

  download(row: CategoryFile) {
    if (this.isDownloading(row)) {
      this.apiFile.download(row.pathFile.split('/').pop())
        .subscribe((data: BlobPart) => {
          // create a blob url representing the data
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);
          // attach blob url to anchor element with download attribute
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute('download', row.name + '.' + row.extension);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }
  }

  previuw(row: CategoryFile) {
    if (row === null || row === undefined)
      return;

    this.windowService.open(this.previuwDocument, {
      title: 'Vista preliminar',
      context: { url: row.pathFile, viewer: this._previewService.getViewer(row) },
      windowClass: 'viewer-document',
    });
  }

  confirmDeleteForlder(row: Category) {
    this.windowRef = this.windowService.open(this.tmpDeleteFolder, {
      title: 'Eliminar carpeta',
      context: { item: row },
    });
  }

  deleteFolder(row: Category) {
    this.storeCategories.delete(row.id);
    this.closeModal();
  }

  confirmDeleteFile(row: CategoryFile) {
    this.windowRef = this.windowService.open(this.tmpDeleteFile, {
      title: 'Eliminar archivo',
      context: { item: row },
    });
  }

  deleteFile(row: CategoryFile) {
    this.storeFiles.delete(row.id);
    this.closeModal();
  }

  isDownloading(row: CategoryFile): boolean {
    return row.pathFile && row.pathFile.trim() !== '' && !row.readonly;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.windowRef.close();
  }

  isAdmin() {
    return this.user.role === ROLES.ADMIN;
  }
}

