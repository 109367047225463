export const authSettings = {
  user: {
    view: ['current-user', 'explorer'],
    edit: ['current-user'],
  },
  contributor: {
    parent: 'user',
      view: 'category',
      create: ['explorer', 'category'],
      edit: ['current-explorer', 'current-category'],
      remove: ['current-explorer', 'current-category'],
  },
  admin: {
    parent: 'contributor',
      view: ['users', 'add-user', 'roles'],
      edit: '*',
      create: '*',
      remove: '*',
  },
};
