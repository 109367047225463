import { createReducer, on, Action } from '@ngrx/store';
import * as RoleActions from '../actions/role.actions';
import { roleAdapter, RoleState, initialRoleState } from '../state/role.state';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Role } from '../../../interfaces/common/role';

const reducer = createReducer(
  initialRoleState,
  on(
    RoleActions.addRoleRequest,
    RoleActions.deleteRoleRequest,
    RoleActions.getRoleRequest,
    RoleActions.loadRolesRequest,
    RoleActions.updateRoleRequest,
    (state) => {
      return { ...state, isLoading: true, error: null };
  }),
  on(
    RoleActions.addRoleFailed,
    RoleActions.deleteRoleFailed,
    RoleActions.getRoleFailed,
    RoleActions.loadRolesFailed,
    RoleActions.updateRoleFailed,
    (state, { error }) => {
      return { ...state, isLoading: false, error: error };
  }),
  on(RoleActions.loadRoles, (state, { roles }) => {
    return roleAdapter.addAll(roles, { ...state, isLoading: false, error: null, isLoad: true });
  }),
  on(
    RoleActions.addRole,
    RoleActions.getRole,
    (state, { role }) => {
    return roleAdapter.addOne(role, { ...state, isLoading: false, error: null });
  }),
  on(
    RoleActions.updateRole,
    (state, { role }) => {
      const roleUpdated: UpdateStr<Role> = { id: role.id, changes: role };
      return roleAdapter.updateOne(roleUpdated, { ...state, isLoading: false, error: null });
  }),
  on(RoleActions.deleteRole, (state, { id }) => {
    return roleAdapter.removeOne(id, state);
  }),
  on(RoleActions.clearRoles, state => {
    return roleAdapter.removeAll({ ...state, selectedRoleId: null, isLoading: false, error: null, isLoad: false });
  }),
  on(RoleActions.selectRole, (state, { id }) => {
    return { ...state, selectedRoleId: id };
  }),
);

export function roleReducer(state: RoleState | undefined, action: Action) {
  return reducer(state, action);
}
