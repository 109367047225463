import { createReducer, on, Action } from '@ngrx/store';
import * as authActions from './auth.actions';
import { initialAuthState, AuthState } from './auth.states';

export const authFeatureKey = 'auth';
const authReducer = createReducer(
  initialAuthState,
  on(authActions.Login, (state, { user }) => ({ ...state, loggedIn: true, user: user })),
  on(authActions.Logout, state => ({ ...state, loggedIn: false, user: undefined })),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
