import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class UploadFilesService {
  uploadFiles = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onUploadFiles() {
    this.uploadFiles.emit();
  }
}
