import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';

import * as CategoryFileAction from '../actions/category-file.actions';
import { CategoryFile } from '../../../interfaces/common/category-file';
import { CategoryFileApi } from '../../../backend/common/api/category-files.api';

@Injectable()
export class CategoryFileEffects {
  constructor(
    private toasterService: NbToastrService,
    private actions$: Actions,
    private api: CategoryFileApi) {}

  private handleError(operation: string, entity: CategoryFile, error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(operation, entity, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        operation,
        entity,
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    this.toasterService.danger('Error al procesar el registro. Vuelva intentarlo nuevamente. Si el error persiste consulte al administrador.');
  }

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFileAction.loadCategoryFilesRequest),
      exhaustMap(() =>
        this.api.getAll().pipe(
          map(categoryFiles => CategoryFileAction.loadCategoryFiles({ categoryFiles })),
          catchError(({ error }) => of(CategoryFileAction.loadCategoryFilesFailed({ error }))),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFileAction.getCategoryFileRequest),
      exhaustMap(action =>
        this.api.getById(action.id).pipe(
          map(categoryFile => CategoryFileAction.getCategoryFile({ categoryFile })),
          catchError(({ error }) => of(CategoryFileAction.getCategoryFileFailed({ error }))),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFileAction.addCategoryFileRequest),
      exhaustMap(action =>
        this.api.add(action.categoryFile).pipe(
          map(categoryFile => {
            this.toasterService.success('Registro creado exitosamente', 'Creación de archivo para la catégoria');
            return CategoryFileAction.addCategoryFile({ categoryFile });
          }),
          catchError(({ error }) => {
            this.handleError('Add CategoryFile', action.categoryFile, error);
            return of(CategoryFileAction.addCategoryFileFailed({ error }));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFileAction.updateCategoryFileRequest),
      exhaustMap(action =>
        this.api.update(action.categoryFile).pipe(
          map(categoryFile => {
            this.toasterService.success('Registro actualizado exitosamente', 'Actualización de archivo para la catégoria');
            return CategoryFileAction.updateCategoryFile({ categoryFile });
          }),
          catchError(({ error }) => {
            this.handleError('Update CategoryFile', action.categoryFile, error);
            return of(CategoryFileAction.updateCategoryFileFailed({ error }));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFileAction.deleteCategoryFileRequest),
      exhaustMap(action =>
        this.api.delete(action.id).pipe(
          map(categoryFile => {
            this.toasterService.success('Registro eliminado exitosamente', 'Eliminación de archivo para la catégoria');
            return CategoryFileAction.deleteCategoryFile({ id: action.id });
          }),
          catchError(({ error }) => {
            this.handleError('Delete CategoryFile ' + action.id, undefined, error);
            return of(CategoryFileAction.deleteCategoryFileFailed({ error }));
          }),
        ),
      ),
    ),
  );
}
