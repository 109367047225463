export const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
export const NUMBERS_PATTERN = '^-?[0-9]+$';
export const PASSWORD_PATTERN = '^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@$!%*?&]{8,}$';
/**
 * (?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}
 * (?=.*[a-z]) => at least one lowercase
 * (?=.*[A-Z]) => at least one uppercase
 * (?=.*\d) => at least one number
 * (?=.*[@$!%*?&]) => at least one special character
 * [A-Za-z\d@$!%*?&]{8,10} => valid Minimum eight and maximum 10 characters
 */
//
