import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';

import * as UserAction from '../actions/user.actions';
import * as AuthAction from '../../auth-store/auth.actions';
import { UsersApi } from '../../../backend/common/api/users.api';
import { User } from '../../../interfaces/common/users';

@Injectable()
export class UserEffects {
  constructor(
    private toasterService: NbToastrService,
    private actions$: Actions,
    private api: UsersApi) {}

  private handleError(operation: string, entity: User, error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(operation, entity, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        operation,
        entity,
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    this.toasterService.danger('Error al procesar el registro. Vuelva intentarlo nuevamente. Si el error persiste consulte al administrador.',"Error",{
    duration: 5000});
  }

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.loadUsersRequest),
      exhaustMap(() =>
        this.api.list().pipe(
          map(users => UserAction.loadUsers({ users })),
          catchError(({ error }) => of(UserAction.loadUsersFailed({ error }))),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.getUserRequest),
      exhaustMap(action =>
        this.api.get(action.id).pipe(
          map(user => UserAction.getUser({ user })),
          catchError(({ error }) => of(UserAction.getUserFailed({ error }))),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.addUserRequest),
      exhaustMap(action =>
        this.api.add(action.user).pipe(
          map(user => {
            this.toasterService.success('Registro creado exitosamente', 'Creación de usuario', {
              status: 'success',
              duration: 5000
            });
            return UserAction.addUser({ user });
          }),
          catchError(({ error }) => {
            this.handleError('Add User', action.user, error);
            return of(UserAction.addUserFailed({ error }));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.updateUserRequest),
      exhaustMap(action =>
        this.api.update(action.user).pipe(
          map(user => {
            this.toasterService.success('Registro actualizado exitosamente', 'Actualización de usuario');
            return UserAction.updateUser({ user });
          }),
          catchError(({ error }) => {
            this.handleError('Update User', action.user, error);
            return of(UserAction.updateUserFailed({ error }));
          }),
        ),
      ),
    ),
  );

  updateCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.updateCurrentUserRequest),
      exhaustMap(action =>
        this.api.updateCurrent(action.user).pipe(
          switchMap(user => {
            this.toasterService.success('Perfil actualizado exitosamente', 'Actualización de perfil');
            return [
              UserAction.updateCurrentUser({ user }),
              AuthAction.Login({user}),
            ];
          }),
          catchError(({ error }) => {
            this.handleError('Update current User', action.user, error);
            return of(UserAction.updateCurrentUserFailed({ error }));
          }),
        ),
      ),
    ),
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.updateUserRoleRequest),
      exhaustMap(action =>
        this.api.updateRole(action.user, action.role).pipe(
          map(user => {
            this.toasterService.success('Perfil actualizado exitosamente', 'Actualización de perfil');
            return UserAction.updateUserRole({ user });
          }),
          catchError(({ error }) => {
            const userError = { id: action.user, role: action.role } as User;
            this.handleError('Update Role by User', userError, error);
            return of(UserAction.updateUserRoleFailed({ error }));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.deleteUserRequest),
      exhaustMap(action =>
        this.api.delete(action.id).pipe(
          map(user => {
            this.toasterService.success('Registro eliminado exitosamente', 'Eliminación de usuario');
            return UserAction.deleteUser({ id: action.id });
          }),
          catchError(({ error }) => {
            this.handleError('Delete User ' + action.id, undefined, error);
            return of(UserAction.deleteUserFailed({ error }));
          }),
        ),
      ),
    ),
  );
}
