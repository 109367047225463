import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Role } from '../../../interfaces/common/role';
import { GenericHttpService } from './infrastructure/generic.http.service';

@Injectable()
export class RoleApi extends GenericHttpService<Role> {
  private readonly apiController: string = 'role';

  public getAll(): Observable<any> {
    return this.get(this.apiController);
  }

  public getById(id: string): Observable<any> {
    return this.getSingle(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.post(this.apiController, item);
  }

  update(item: any): Observable<any> {
    return this.put(`${this.apiController}/${item.id}`, item);
  }

  delete(id: string): Observable<boolean> {
    return this.remove(`${this.apiController}/${id}`);
  }
}
