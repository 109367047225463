import { createReducer, on, Action } from '@ngrx/store';
import * as CategoryActions from '../actions/category.actions';
import { CategoryAdapter, CategoryState, initialCategoryState } from '../state/category.state';
import { Category } from '../../../interfaces/common/category';
import { UpdateStr } from '@ngrx/entity/src/models';

const reducer = createReducer(
  initialCategoryState,
  on(
    CategoryActions.addCategoryRequest,
    CategoryActions.deleteCategoryRequest,
    CategoryActions.getCategoryRequest,
    CategoryActions.loadCategoriesRequest,
    CategoryActions.updateCategoryRequest,
    (state) => {
      return { ...state, isLoading: true, error: null };
  }),
  on(
    CategoryActions.addCategoryFailed,
    CategoryActions.deleteCategoryFailed,
    CategoryActions.getCategoryFailed,
    CategoryActions.loadCategoriesFailed,
    CategoryActions.updateCategoryFailed,
    (state, { error }) => {
      return { ...state, isLoading: false, error: error };
  }),
  on(CategoryActions.loadCategories, (state, { categories }) => {
    return CategoryAdapter.addAll(categories, { ...state, isLoading: false, error: null, isLoad: true });
  }),
  on(
    CategoryActions.addCategory,
    CategoryActions.getCategory,
    (state, { category }) => {
    return CategoryAdapter.addOne(category, { ...state, isLoading: false, error: null });
  }),
  on(
    CategoryActions.updateCategory,
    (state, { category }) => {
      const categoryUpdated: UpdateStr<Category> = { id: category.id, changes: category };
      return CategoryAdapter.updateOne(categoryUpdated, { ...state, isLoading: false, error: null });
  }),
  on(CategoryActions.deleteCategory, (state, { id }) => {
    return CategoryAdapter.removeOne(id, state);
  }),
  on(CategoryActions.clearCategories, state => {
    return CategoryAdapter.removeAll({ ...state, selectedCategoryId: null, isLoading: false, error: null, isLoad: false });
  }),
  on(CategoryActions.selectCategory, (state, { id }) => {
    return { ...state, selectedCategoryId: id };
  }),
);

export function categoryReducer(state: CategoryState | undefined, action: Action) {
  return reducer(state, action);
}
