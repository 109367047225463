import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryFile } from '../../../interfaces/common/category-file';

export interface CategoryFileState extends EntityState<CategoryFile> {
  isLoad: boolean;
  isLoading?: boolean;
  error?: any;
  selectedCategoryFileId: string | null;
}

export const categoryFileAdapter: EntityAdapter<CategoryFile> = createEntityAdapter<CategoryFile>({
  selectId: selectCategoryId,
  sortComparer: sortByName,
});

export const initialCategoryFileState: CategoryFileState = categoryFileAdapter.getInitialState({
  isLoad: false,
  isLoading: false,
  error: null,
  selectedCategoryFileId: null,
});

function selectCategoryId(categoryFile: CategoryFile): string {
  return categoryFile.id;
}

function sortByName(categoryFileA: CategoryFile, categoryFileB: CategoryFile): number {
  if (categoryFileA.name && categoryFileB.name) {
    return categoryFileA.name.localeCompare(categoryFileB.name);
  } else {
    if (categoryFileA.name) return 1;
    else return -1;
  }
}
