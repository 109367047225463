import { createSelector } from '@ngrx/store';
import * as fromFeature from '../app-store.reducers';
import { CategoryAdapter, CategoryState } from '../state/category.state';

export const selectCategoryState = createSelector(fromFeature.getAppState, (state: fromFeature.PageState) => state.categories);

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal } = CategoryAdapter.getSelectors();

const getSelectedCategoryId = (state: CategoryState) => state.selectedCategoryId;
const getError = (state: CategoryState): any => state.error;
const getIsLoading = (state: CategoryState): boolean => state.isLoading;

export const selectAllCategories = createSelector(selectCategoryState, selectAll);
export const selectCategoryIds = createSelector(selectCategoryState, selectIds);
export const selectCategoryEntities = createSelector(selectCategoryState, selectEntities);
export const selectCategoryTotal = createSelector(selectCategoryState, selectTotal);
export const selectCurrentCategoryId = createSelector(selectCategoryState, getSelectedCategoryId);

// tslint:disable-next-line: max-line-length
export const selectCurrentCategory = createSelector(selectCategoryEntities, selectCurrentCategoryId, (categoryEntities, categoryId) => categoryEntities[categoryId]);
export const selectCategoryError = createSelector(selectCategoryState, getError);
export const selectCategoryIsLoading = createSelector(selectCategoryState, getIsLoading);
export const selectCategoryById = (id: string) => createSelector(selectCategoryState, categorysState => categorysState.entities[id]);
