import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Category } from '../../../interfaces/common/category';

export interface CategoryState extends EntityState<Category> {
  isLoad: boolean;
  isLoading?: boolean;
  error?: any;
  selectedCategoryId: string | null;
}

export const CategoryAdapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  selectId: selectCategoryId,
  sortComparer: sortByName,
});

export const initialCategoryState: CategoryState = CategoryAdapter.getInitialState({
  isLoad: false,
  isLoading: false,
  error: null,
  selectedCategoryId: null,
});

function selectCategoryId(category: Category): string {
  return category.id;
}

function sortByName(categoryA: Category, categoryB: Category): number {
  return categoryA.name.localeCompare(categoryB.name);
}
