import { Category } from '../../../../@core/interfaces/common/category';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { CategoryStore } from '../../../../@core/root-store/app-store/store/category.store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbAccessChecker } from '@nebular/security';
import { map } from 'rxjs/operators';

export enum CategoryFormMode {
  VIEW = 'View',
  EDIT = 'Edit',
}

@Component({
  selector: 'ngx-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryEditFormComponent {
  @Input() category: Category;
  @Input() isAdd: boolean;
  categoryForm: FormGroup;
  mode: CategoryFormMode;

  setViewMode(viewMode: CategoryFormMode) {
    this.mode = viewMode;
  }

  constructor(public windowRef: NbWindowRef, private accessChecker: NbAccessChecker, private store: CategoryStore, private fb: FormBuilder) {
    this.initUserForm();
    this.loadUserData();
    this.accessChecker.isGranted('edit', 'category').pipe(map(authorize => {
      this.setViewMode(authorize ? CategoryFormMode.EDIT : CategoryFormMode.VIEW);
      return authorize;
    }));
  }

  initUserForm() {
    this.categoryForm = this.fb.group({
        id: this.fb.control(''),
        parentId: this.fb.control(''),
        name: this.fb.control('', [Validators.required]),
        description: this.fb.control(''),
      });
  }

  loadUserData() {
    if (!this.category) {
      if (this.windowRef && this.windowRef.config.context) {
        this.category = this.windowRef.config.context['category'];
        this.isAdd = this.windowRef.config.context['isAdd'];
      }
    }

    this.categoryForm.patchValue(this.category);
  }

  get canEdit(): boolean {
    return this.mode !== CategoryFormMode.EDIT;
  }

  convertToCategory(value: any): Category {
    const category: Category = value;
    return category;
  }

  upsert() {
    const category: Category = this.convertToCategory(this.categoryForm.value);
    // console.log(this.mode);
    // if (this.mode === CategoryFormMode.EDIT) {
      if (this.isAdd) this.store.add(category);
      else this.store.update(category);
    // }
    this.close();
  }

  close() {
    this.windowRef.close();
  }
}
