import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as Actions from '../actions/user.actions';
// reducers
import { UserState } from '../state/user.state';
import { User } from '../../../interfaces/common/users';
import * as fromSelectors from '../selectors/user.selectors';

@Injectable()
export class UserStore {
  constructor(private store: Store<UserState>) {}

  serverList() {
    this.store.dispatch(Actions.loadUsersRequest());
  }

  add(user: User) {
    this.store.dispatch(Actions.addUserRequest({ user }));
  }

  update(user: User) {
    this.store.dispatch(Actions.updateUserRequest({ user }));
  }

  updateCurrent(user: User) {
    this.store.dispatch(Actions.updateCurrentUserRequest({ user }));
  }

  updateUserRole(user: string, role: string) {
    this.store.dispatch(Actions.updateUserRoleRequest({ user, role }));
  }

  delete(id: string) {
    this.store.dispatch(Actions.deleteUserRequest({ id }));
  }

  getError() {
    return this.store.select(fromSelectors.selectUserError);
  }

  getIsLoading() {
    return this.store.select(fromSelectors.selectUserIsLoading);
  }

  getSelected() {
    return this.store.select(fromSelectors.selectCurrentUser);
  }

  getByID(id: string) {
    return this.store.select(fromSelectors.selectUserById(id));
  }

  getAllUser() {
    return this.store.pipe(select(fromSelectors.selectAllUsers));
  }
}
