import { Injectable } from '@angular/core';
import { CategoryFile } from '../interfaces/common/category-file';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  constructor() { }

  getViewer(categoryFile: CategoryFile): string {
    if (categoryFile === null ||
        categoryFile === undefined ||
        categoryFile.extension === null ||
        categoryFile.extension === undefined ||
        categoryFile.extension === '' ||
        categoryFile.extension.toLowerCase() !== 'pdf') {
          return 'google';
        }

     return 'pdf';
  }
}
