import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxValidationMessageComponent } from './validation-message/validation-message.component';
import { FileUploaderComponent } from './upload-file/upload-file.component';
import { UploadFilesService } from './upload-file/upload-file.service';
import { NgxDocViewerComponent } from './document-viewer/document-viewer.component';
import { NgJsTreeComponent } from './ng-js-tree/ng-js-tree.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NbAlertModule, NbButtonModule, NbIconModule, NbInputModule } from '@nebular/theme';

const COMPONENTS = [
  NgxValidationMessageComponent,
  FileUploaderComponent,
  NgxDocViewerComponent,
  NgJsTreeComponent,
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    PdfViewerModule,
    NbAlertModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    ReactiveFormsModule
  ],
  providers: [UploadFilesService],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ComponentsModule { }
