import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
// reducers
import { AuthState } from './auth.states';
import { User } from '../../interfaces/common/users';
import * as fromSelectors from './auth.selectors';

@Injectable()
export class AuthStore {

  constructor(private store: Store<AuthState>) {}

  login(user: User) {
    this.store.dispatch(AuthActions.Login({ user }));
  }

  logout(redirect: string) {
    this.store.dispatch(AuthActions.Logout({ redirect }));
  }

  getAuthenticatedUser() {
    return this.store.select(fromSelectors.selectAuthUser);
  }

  isAuthenticated() {
    return this.store.select(fromSelectors.isLoggedIn);
  }
}
