import { createReducer, on, Action } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import { userAdapter, UserState, initialUserState } from '../state/user.state';
import { UpdateStr } from '@ngrx/entity/src/models';
import { User } from '../../../interfaces/common/users';

const reducer = createReducer(
  initialUserState,
  on(
    UserActions.addUserRequest,
    UserActions.deleteUserRequest,
    UserActions.getUserRequest,
    UserActions.loadUsersRequest,
    UserActions.updateCurrentUserRequest,
    UserActions.updateUserRequest,
    UserActions.updateUserRoleRequest,
    (state) => {
      return { ...state, isLoading: true, error: null };
  }),
  on(
    UserActions.addUserFailed,
    UserActions.deleteUserFailed,
    UserActions.getUserFailed,
    UserActions.loadUsersFailed,
    UserActions.updateCurrentUserFailed,
    UserActions.updateUserFailed,
    UserActions.updateUserRoleFailed,
    (state, { error }) => {
      return { ...state, isLoading: false, error: error };
  }),
  on(UserActions.loadUsers, (state, { users }) => {
    return userAdapter.addAll(users, { ...state, isLoading: false, error: null, isLoad: true });
  }),
  on(
    UserActions.addUser,
    UserActions.getUser,
    (state, { user }) => {
    return userAdapter.addOne(user, { ...state, isLoading: false, error: null });
  }),
  on(
    UserActions.updateUser,
    UserActions.updateCurrentUser,
    UserActions.updateUserRole,
    (state, { user }) => {
      const userUpdated: UpdateStr<User> = { id: user.id, changes: user };
    return userAdapter.updateOne(userUpdated, { ...state, isLoading: false, error: null });
  }),
  on(UserActions.deleteUser, (state, { id }) => {
    return userAdapter.removeOne(id, state);
  }),
  on(UserActions.clearUsers, state => {
    return userAdapter.removeAll({ ...state, selectedUserId: null, isLoading: false, error: null, isLoad: false });
  }),
  on(UserActions.selectUser, (state, { id }) => {
    return { ...state, selectedUserId: id };
  }),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
