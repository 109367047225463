import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as Actions from '../actions/role.actions';
// reducers
import { RoleState } from '../state/role.state';
import { Role } from '../../../interfaces/common/role';
import * as fromSelectors from '../selectors/role.selectors';

@Injectable()
export class RoleStore {
  constructor(private store: Store<RoleState>) {}

  serverList() {
    this.store.dispatch(Actions.loadRolesRequest());
  }

  add(role: Role) {
    this.store.dispatch(Actions.addRoleRequest({ role }));
  }

  update(role: Role) {
    this.store.dispatch(Actions.updateRoleRequest({ role }));
  }

  delete(id: string) {
    this.store.dispatch(Actions.deleteRoleRequest({ id }));
  }

  getError() {
    return this.store.select(fromSelectors.selectRoleError);
  }

  getIsLoading() {
    return this.store.select(fromSelectors.selectRoleIsLoading);
  }

  getSelected() {
    return this.store.select(fromSelectors.selectCurrentRole);
  }

  getByID(id: string) {
    return this.store.select(fromSelectors.selectRoleById(id));
  }

  getAllRole() {
    return this.store.pipe(select(fromSelectors.selectAllRoles));
  }
}
