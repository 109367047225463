import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as Actions from '../actions/category-file.actions';
// reducers
import { CategoryFileState } from '../state/category-file.state';
import { CategoryFile } from '../../../interfaces/common/category-file';
import * as fromSelectors from '../selectors/category-file.selectors';

@Injectable()
export class CategoryFileStore {
  constructor(private store: Store<CategoryFileState>) {}

  serverList() {
    this.store.dispatch(Actions.loadCategoryFilesRequest());
  }

  add(categoryFile: CategoryFile) {
    this.store.dispatch(Actions.addCategoryFileRequest({ categoryFile }));
  }

  update(categoryFile: CategoryFile) {
    this.store.dispatch(Actions.updateCategoryFileRequest({ categoryFile }));
  }

  delete(id: string) {
    this.store.dispatch(Actions.deleteCategoryFileRequest({ id }));
  }

  getError() {
    return this.store.select(fromSelectors.selectCategoryFileError);
  }

  getIsLoading() {
    return this.store.select(fromSelectors.selectCategoryFileIsLoading);
  }

  getSelected() {
    return this.store.select(fromSelectors.selectCurrentCategoryFile);
  }

  getByID(id: string) {
    return this.store.select(fromSelectors.selectCategoryFileById(id));
  }

  getAllCategoryFile() {
    return this.store.pipe(select(fromSelectors.selectAllCategoryFiles));
  }

  getRecentCategoryFiles() {
    return this.store.pipe(select(fromSelectors.selectRecentCategoryFiles));
  }
}
