import { User } from './../../@core/interfaces/common/users';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  DoCheck,
  IterableDiffers,
  IterableDiffer,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { ROLES } from '../../@auth/roles';
import { SortingOrder } from '../../pages/catalogs/categories-files/category-file.component';

/** Variable JQuery */
declare var $: any;

/** Determine the tree node's state structure (NgJsTree CUSTOM) */
export interface TreeNodeState {
  /** Should the node be loaded. Defaults to False */
  loaded?: boolean;
  /** Should the node be opened. Defaults to False */
  opened?: boolean;
  /** Should the node be selected. Defaults to False */
  selected?: boolean;
  /** Should the node be disabled. Defaults to False */
  disabled?: boolean;
}

/** Determine the tree node structure (NgJsTree CUSTOM) */
export interface TreeNode {
  /** Identifier of database */
  id: number | string;
  /** Simple Text or HTML to show in the tree node */
  text: string;
  /** Icon of the tree node */
  icon?: string;
  /** Original children's tree node */
  children?: TreeNode[];
  /** Children's tree node to search*/
  childrenToSearch?: TreeNode[];
  /** Object of database */
  data?: any;
  /** Initial state of the tree node */
  state?: TreeNodeState;
  /** Detemine of type's group in the drag and drop */
  type?: string;
}

@Component({
  selector: 'ngx-ng-js-tree',
  templateUrl: './ng-js-tree.component.html',
  styleUrls: ['./ng-js-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NgJsTreeComponent implements OnInit, OnChanges, DoCheck {
  /** Thread for search */
  private to: any;
  /** HTML Tree component */
  private tree: JSTree;
  /** Time of delay for the thread */
  private delayMilisecond = 500;
  private _yScrollPosition:  number = 0;
  /** String to search in the nodes of the tree */
  @Input() search: string;
  /** Data for the tree's node */
  @Input() data: TreeNode[];
  /** Data for the tree's node */
  @Input() node: any;
  /** User that has login in the system */
  @Input() user: User;
  /** Click action into the tree control (create, edit, delete, previuw, download, dnd, sort) */
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  /** Utilitie for alidation of new changes of the input data */
  private iterableDifferData: IterableDiffer<TreeNode> | null | undefined;

  constructor(private iterable: IterableDiffers) {
    this.iterableDifferData = this.iterable.find([]).create(null);
  }

  ngOnInit(): void {
    // this.createTree();
    if (this.data === undefined || this.data === null) this.data = [];
    const typeFiles = [ 'default', 'pdf', 'adobe', 'csv', 'file', 'archive', 'excel', 'word', 'powerpoint', 'image,', 'video', 'audio', 'unknown' ];
    let pluginsTree = ['contextmenu', 'search', 'state', 'types', 'wholerow'];
    if (this.user.role === ROLES.ADMIN) pluginsTree = ['contextmenu', 'dnd', 'search', 'state', 'types', 'wholerow'];
    const originalThisContext: this = this;
    // tslint:disable-next-line: ban
    this.tree = $('#jstree');
    this.tree.jstree({
      core: { animation: 0,
              check_callback: true,
              themes : { stripes: true },
              data: this.data,
              dblclick_toggle: false,
              restore_focus: true,
              strings: { 'Loading ...': 'Cargando datos...' }},
      types: {
        '#': { max_children: -1, max_depth: -1, valid_children: ['root'] },
        root: { icon: 'fas fa-sort-amount-down-alt', valid_children: [...typeFiles] },
        default: { valid_children : [...typeFiles] },
        pdf: { icon: 'far fa-file-pdf', valid_children: [] },
        adobe: { icon: 'far fa-adobe', valid_children: [] },
        csv: { icon: 'far fa-file-csv', valid_children: [] },
        file: { icon: 'far fa-file-alt', valid_children: [] },
        archive: { icon: 'far fa-file-archive', valid_children: [] },
        excel: { icon: 'far fa-file-excel', valid_children: [] },
        word: { icon: 'far fa-file-word', valid_children: [] },
        powerpoint: { icon: 'far fa-file-powerpoint', valid_children: [] },
        image: { icon: 'far fa-file-image', valid_children: [] },
        video: { icon: 'far fa-file-video', valid_children: [] },
        audio: { icon: 'far fa-file-audio', valid_children: [] },
        unknown: { icon: 'far fa-question-circle', valid_children: [] },
      },
      contextmenu: { items: (node: any) => {
        const fullNode = this.getWithChildren(node);

        const items = {
          create: {
            separator_before: false, separator_after: false, label: 'Crear', action: false, icon: 'fas fa-plus-square',
            submenu: {
              folder: {
                separator_before: false, separator_after: false, label: 'Carpeta', icon: 'fas fa-folder',
                action: (objContextMenu: any) => { this.action.emit({ node, action: 'create', type: 'folder' }); },
              },
              file: {
                separator_before: false, separator_after: false, label: 'Archivo', icon: 'fas fa-file',
                action: (objContextMenu: any) => { this.action.emit({ node, action: 'create', type: 'file' }); },
              },
            },
          },
          edit: {
            separator_before: false, separator_after: false, label: 'Editar', icon: 'fas fa-edit',
            action: (objContextMenu: any) => {
              if (this.isFolder(node.type)) {
                this.action.emit({ node, action: 'edit', type: 'folder' });
              } else {
                this.action.emit({ node, action: 'edit', type: 'file' });
              }
            },
          },
          delete: {
            separator_before: false, separator_after: false, label: 'Eliminar', icon: 'fas fa-trash-alt',
            action: (objContextMenu: any) => {
              if (this.isFolder(node.type)) {
                this.action.emit({ node, action: 'delete', type: 'folder' });
              } else {
                this.action.emit({ node, action: 'delete', type: 'file' });
              }
            },
          },
          previuw: {
            separator_before: true, separator_after: false, label: 'Vista preliminar', icon: 'fas fa-eye',
            action: (objContextMenu: any) => { this.action.emit({ node, action: 'previuw', type: 'file' }); },
          },
          download: {
            separator_before: false, separator_after: false, label: 'Descargar', icon: 'fas fa-download',
            action: (objContextMenu: any) => { this.action.emit({ node, action: 'download', type: 'file' }); },
          },
          sortByName: {
            separator_before: false,
            separator_after: false,
            label: 'Ordenar por nombre',
            icon: node.data.sortingOrderForName === SortingOrder.ASCENDING ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc',
            action: (objContextMenu: any) => {
              this.tree.jstree(true).open_node(fullNode, function(openedNode: TreeNode) {
                if (openedNode === null || openedNode === undefined)
                  return;

                  const domNode = originalThisContext.tree.jstree(true).get_node(openedNode.id, true);

                  if (!domNode)
                    return;

                  originalThisContext._yScrollPosition = originalThisContext.getTreeHierarchyHeight(openedNode.id);
              });
              this.action.emit({ node: fullNode, action: 'sort-by-name', type: 'folder' });
            },
          },
          sortByDate: {
            separator_before: false,
            separator_after: false,
            label: 'Ordenar por fecha',
            icon: node.data.sortingOrderForDate === SortingOrder.ASCENDING ? 'fa fa-sort-numeric-desc' : 'fa fa-sort-numeric-asc',
            action: (objContextMenu: any) => {
              this.tree.jstree(true).open_node(fullNode, function(openedNode: TreeNode) {
                if (openedNode === null || openedNode === undefined)
                  return;

                  const domNode = originalThisContext.tree.jstree(true).get_node(openedNode.id, true);

                  if (!domNode)
                    return;

                  originalThisContext._yScrollPosition = originalThisContext.getTreeHierarchyHeight(openedNode.id);
              });
              this.action.emit({ node: fullNode, action: 'sort-by-date', type: 'folder' });
            },
          }
        };

        if (!this.isFolder(node.type) || !this.canAdd()) delete items.create;
        if (!this.canEdit(node.data.createdBy)) delete items.edit;
        if (this.isFolder(node.type)) {
          delete items.previuw;
          delete items.download;
        } else if (!this.isDownloading(node)) delete items.download;
        if (!this.canDelete(node)) delete items.delete;
        if (!this.isFolder(node.type) ||
          node.children === null ||
          node.children === undefined ||
          node.children.length === 0) {
          delete items.sortByName;
        }
        if (node.children === null ||
          node.children === undefined ||
          node.children.length === 0 ||
          node.children.filter(c => c.type !== 'root' && c.type !== 'default' && c.type !== 'folder').length === 0) {
          delete items.sortByDate;
        }

        return items;
      }},
      plugins: pluginsTree
    }).on('after_open.jstree', function (e, data) {
      // tslint:disable-next-line: ban
      $('.TreeTooltip').tooltipster({
        contentAsHTML: true,
        animation: 'fade',
        debug: false,
      });
    }).on('refresh.jstree', function(e, data) {
      if (originalThisContext._yScrollPosition === null ||
        originalThisContext._yScrollPosition === undefined ||
        originalThisContext._yScrollPosition === 0)
        return;

        $(".scrollable-tree").scrollTop(originalThisContext._yScrollPosition);
    });

    // tslint:disable-next-line: ban
    $(() => {
      // tslint:disable-next-line: ban
      $(document).on('dnd_stop.vakata', (event: any, drag: any) => {
        const parentId = drag.event.target.parentElement.id;
        const nodeTree = this.tree.jstree(true).get_node(drag.element.id);
        // Validate if the element dragged in folders
        if (nodeTree.data.parentId !== parentId) {
          const node = { data: { ...nodeTree.data, parentId }};
          if (this.isFolder(nodeTree.type)) {
            this.action.emit({ node, action: 'dnd', type: 'folder' });
          } else {
            this.action.emit({ node, action: 'dnd', type: 'file' });
          }
        }
      });
    });
  }

  private getWithChildren(nodeWithoutChildren: TreeNode): TreeNode {
    if (nodeWithoutChildren.children === null ||
      nodeWithoutChildren.children === undefined ||
      nodeWithoutChildren.children.length === 0) {
        return nodeWithoutChildren;
      }

    const toReturn = {...nodeWithoutChildren};
    toReturn.childrenToSearch = [];

    toReturn.children.forEach(currentChildId => {
      toReturn.childrenToSearch.push(this.getWithChildren(this.tree.jstree(true).get_node(currentChildId, false)));
    });

    return toReturn;
  }

  getTreeHierarchyHeight(nodeId: string | number): number {
    const typeNode: TreeNode = this.tree.jstree(true).get_node(nodeId, false);
    const domNode = this.tree.jstree(true).get_node(nodeId, true);

    if (!domNode || !typeNode)
      return 0;

    if (typeNode.type !== 'default' && typeNode.type !== 'folder')
      return $(domNode).offset().top /*+ $(domNode).height()*/;

    const parentNodeId = this.tree.jstree(true).get_parent(typeNode.id);

    let parentHeight: number = 0;

    if (parentNodeId !== null && parentNodeId !== undefined && parentNodeId !== '') {
      parentHeight = this.getTreeHierarchyHeight(parentNodeId);
    }

    return parentHeight === 0 ? $(domNode).height() : parentHeight;
    // this._yScrollPosition = domNode.offset().top + (domNode.height() * 2.5)
    return /*$(domNode).offset().top + */ $(domNode).height() + parentHeight
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.search !== undefined && changes.search !== null &&
      changes.search.currentValue !== undefined && changes.search.currentValue !== null &&
      changes.search.previousValue !== undefined && changes.search.previousValue !== null &&
      changes.search.currentValue !== changes.search.previousValue) {
      this.applySearch(changes.search.currentValue);
    }
  }

  ngDoCheck() {
    const dataChanges = this.iterableDifferData.diff(this.data);
    if (dataChanges) {
      this.startTree();
    }
  }

  forceTreeRefresh(): void {
    this.startTree();
  }

  startTree() {
    this.tree.jstree(true).settings.core.data = this.data;
    this.tree.jstree(true).refresh(false, false);
  }

  private applySearch(textSearch: string): void {
    if (this.to) { clearTimeout(this.to); }
    this.to = setTimeout(function () {
      // tslint:disable-next-line: ban
      $('#jstree').jstree(true).search(textSearch);
    }, this.delayMilisecond);
  }

  isDownloading(node: any): boolean {
    if (!this.isFolder(node.type)) {
      return node.data.pathFile && node.data.pathFile.trim() !== '' && !node.data.readonly;
    }
    return false;
  }

  isFolder(type: string): boolean {
    return type === 'root' || type === 'default';
  }

  canAdd(): boolean {
    return (this.user.role === ROLES.ADMIN) || (this.user.role === ROLES.CONTRIBUTOR);
  }

  canEdit(createdBy: string): boolean {
    if (this.user.role === ROLES.ADMIN) return true;
    if (this.user.role === ROLES.CONTRIBUTOR) {
      return createdBy === this.user.login;
    }
    return false;
  }

  canDelete(node: any): boolean {
    if (this.user.role === ROLES.ADMIN) {
      return !(node.children !== undefined && node.children !== null && node.children.length > 0);
    }
    return false;
  }
}
