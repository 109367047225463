import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromStates from './state';
import * as fromReducers from './reducer';

export const pageFeatureKey = 'app';

export interface PageState {
  users: fromStates.UserState;
  roles: fromStates.RoleState;
  categories: fromStates.CategoryState;
  categoryFiles: fromStates.CategoryFileState;
}

export const reducers: ActionReducerMap<PageState> = {
  users: fromReducers.userReducer,
  roles: fromReducers.roleReducer,
  categories: fromReducers.categoryReducer,
  categoryFiles: fromReducers.categoryFileReducer,
};

export const getAppState = createFeatureSelector<PageState>(pageFeatureKey);
