import { UserEffects } from './user.effects';
import { RoleEffects } from './role.effects';
import { CategoryEffects } from './category.effects';
import { CategoryFileEffects } from './category-file.effects';

export const effects: any[] = [
  UserEffects,
  RoleEffects,
  CategoryEffects,
  CategoryFileEffects,
];
