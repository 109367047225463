import { CategoryFile } from './../../../interfaces/common/category-file';
import { createSelector } from '@ngrx/store';
import * as fromFeature from '../app-store.reducers';
import { categoryFileAdapter, CategoryFileState } from '../state/category-file.state';

export const selectCategoryFileState = createSelector(fromFeature.getAppState, (state: fromFeature.PageState) => state.categoryFiles);

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal } = categoryFileAdapter.getSelectors();

const getSelectedCategoryFileId = (state: CategoryFileState) => state.selectedCategoryFileId;
const getError = (state: CategoryFileState): any => state.error;
const getIsLoading = (state: CategoryFileState): boolean => state.isLoading;

export const selectAllCategoryFiles = createSelector(selectCategoryFileState, selectAll);
export const selectCategoryFileIds = createSelector(selectCategoryFileState, selectIds);
export const selectCategoryFileEntities = createSelector(selectCategoryFileState, selectEntities);
export const selectCategoryFileTotal = createSelector(selectCategoryFileState, selectTotal);
export const selectCurrentCategoryFileId = createSelector(selectCategoryFileState, getSelectedCategoryFileId);

// tslint:disable-next-line: max-line-length
export const selectCurrentCategoryFile = createSelector(selectCategoryFileEntities, selectCurrentCategoryFileId, (CategoryFileEntities, CategoryFileId) => CategoryFileEntities[CategoryFileId]);
export const selectCategoryFileError = createSelector(selectCategoryFileState, getError);
export const selectCategoryFileIsLoading = createSelector(selectCategoryFileState, getIsLoading);
export const selectCategoryFileById = (id: string) => createSelector(selectCategoryFileState, CategoryFilesState => CategoryFilesState.entities[id]);
export const selectRecentCategoryFiles = createSelector(selectAllCategoryFiles, categories => {
  const dateComparer = new Date();
  dateComparer.setDate(dateComparer.getDate() - 15);
  return categories.filter((category: CategoryFile) => (new Date(category.updatedAt)) > dateComparer);
});
