import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';

import * as CategoryAction from '../actions/category.actions';
import { CategoryApi } from '../../../backend/common/api/category.api';
import { Category } from '../../../interfaces/common/category';

@Injectable()
export class CategoryEffects {
  constructor(
    private toasterService: NbToastrService,
    private actions$: Actions,
    private api: CategoryApi) {}

  private handleError(operation: string, entity: Category, error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(operation, entity, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        operation,
        entity,
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    this.toasterService.danger('Error al procesar el registro. Vuelva intentarlo nuevamente. Si el error persiste consulte al administrador.');
  }

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryAction.loadCategoriesRequest),
      exhaustMap(() =>
        this.api.getAll().pipe(
          map(categories => CategoryAction.loadCategories({ categories })),
          catchError(({ error }) => of(CategoryAction.loadCategoriesFailed({ error }))),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryAction.getCategoryRequest),
      exhaustMap(action =>
        this.api.getById(action.id).pipe(
          map(category => CategoryAction.getCategory({ category })),
          catchError(({ error }) => of(CategoryAction.getCategoryFailed({ error }))),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryAction.addCategoryRequest),
      exhaustMap(action =>
        this.api.add(action.category).pipe(
          map(category => {
            this.toasterService.success('Registro creado exitosamente', 'Creación de catégoria');
            return CategoryAction.addCategory({ category });
          }),
          catchError(({ error }) => {
            this.handleError('Add Category', action.category, error);
            return of(CategoryAction.addCategoryFailed({ error }));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryAction.updateCategoryRequest),
      exhaustMap(action =>
        this.api.update(action.category).pipe(
          map(category => {
            this.toasterService.success('Registro actualizado exitosamente', 'Actualización de catégoria');
            return CategoryAction.updateCategory({ category });
          }),
          catchError(({ error }) => {
            this.handleError('Update Category', action.category, error);
            return of(CategoryAction.updateCategoryFailed({ error }));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryAction.deleteCategoryRequest),
      exhaustMap(action =>
        this.api.delete(action.id).pipe(
          map(category => {
            this.toasterService.success('Registro eliminado exitosamente', 'Eliminación de catégoria');
            return CategoryAction.deleteCategory({ id: action.id });
          }),
          catchError(({ error }) => {
            this.handleError('Delete Category ' + action.id, undefined, error);
            return of(CategoryAction.deleteCategoryFailed({ error }));
          }),
        ),
      ),
    ),
  );
}
