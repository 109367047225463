import { createSelector } from '@ngrx/store';
import * as fromFeature from '../app-store.reducers';
import { userAdapter, UserState } from '../state/user.state';

export const selectUserState = createSelector(fromFeature.getAppState, (state: fromFeature.PageState) => state.users);

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal } = userAdapter.getSelectors();

const getSelectedUserId = (state: UserState) => state.selectedUserId;
const getError = (state: UserState): any => state.error;
const getIsLoading = (state: UserState): boolean => state.isLoading;

export const selectAllUsers = createSelector(selectUserState, selectAll);
export const selectUserIds = createSelector(selectUserState, selectIds);
export const selectUserEntities = createSelector(selectUserState, selectEntities);
export const selectUserTotal = createSelector(selectUserState, selectTotal);
export const selectCurrentUserId = createSelector(selectUserState, getSelectedUserId);

export const selectCurrentUser = createSelector(selectUserEntities, selectCurrentUserId, (userEntities, userId) => userEntities[userId]);
export const selectUserError = createSelector(selectUserState, getError);
export const selectUserIsLoading = createSelector(selectUserState, getIsLoading);
export const selectUserById = (id: string) => createSelector(selectUserState, usersState => usersState.entities[id]);
