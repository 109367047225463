import { createAction, props } from '@ngrx/store';
import { Category } from '../../../interfaces/common/category';
import { UpdateStr } from '@ngrx/entity/src/models';

export const getCategoryRequest = createAction('[Category/API] Get Category Request', props<{ id: string }>());
export const getCategory = createAction('[Category/API] Get Category', props<{ category: Category }>());
export const getCategoryFailed = createAction('[Category/API] Get Category Failed', props<{ error: any }>());

export const loadCategoriesRequest = createAction('[Category/API] Load clearCategories Request');
export const loadCategories = createAction('[Category/API] Load clearCategories', props<{ categories: Category[] }>());
export const loadCategoriesFailed = createAction('[Category/API] Load clearCategories Failed', props<{ error: any }>());

export const addCategoryRequest = createAction('[Category/API] Add Category Request', props<{ category: Category }>());
export const addCategory = createAction('[Category/API] Add Category', props<{ category: Category }>());
export const addCategoryFailed = createAction('[Category/API] Add Category Failed', props<{ error: any }>());

export const updateCategoryRequest = createAction('[Category/API] Update Category Request', props<{ category: Category }>());
export const updateCategory = createAction('[Category/API] Update Category', props<{ category: UpdateStr<Category> }>());
export const updateCategoryFailed = createAction('[Category/API] Update Category Failed', props<{ error: any }>());

export const deleteCategoryRequest = createAction('[Category/API] Delete Category Request', props<{ id: string }>());
export const deleteCategory = createAction('[Category/API] Delete Category', props<{ id: string }>());
export const deleteCategoryFailed = createAction('[Category/API] Delete Category Failed', props<{ error: any }>());

export const clearCategories = createAction('[Category/API] Clear Categories');
export const selectCategory = createAction('[Category/API] Select Category', props<{ id: string }>());
