import { Injectable } from '@angular/core';
import { HttpParams, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GenericHttpService } from './generic.http.service';
import { ApiData } from '../../../../interfaces/infrastructure/api';

@Injectable()
export class GenericApi<T> implements ApiData<T> {
  private apiController: string = '';
  constructor(private api: GenericHttpService<T>) {}

  get gridDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<HttpEvent<T[]>> {
    const params = new HttpParams()
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.api.get(this.apiController, { params });
  }

  getCurrent(): Observable<HttpEvent<T>> {
    return this.api.getSingle(`${this.apiController}/current`);
  }

  get(id: number): Observable<HttpEvent<T>> {
    return this.api.getSingle(`${this.apiController}/${id}`);
  }

  delete(id: number): Observable<HttpEvent<boolean>> {
    return this.api.remove(`${this.apiController}/${id}`);
  }

  add(item: T): Observable<HttpEvent<T>> {
    return this.api.post(this.apiController, item);
  }

  updateCurrent(item: T): Observable<HttpEvent<T>> {
    return this.api.put(`${this.apiController}/current`, item);
  }

  update(item: T, id: number): Observable<HttpEvent<T>> {
    return this.api.put(`${this.apiController}/${id}`, item);
  }
}
