import { createAction, props } from '@ngrx/store';
import { Role } from '../../../interfaces/common/role';
import { UpdateStr } from '@ngrx/entity/src/models';

export const getRoleRequest = createAction('[Role/API] Get Role Request', props<{ id: string }>());
export const getRole = createAction('[Role/API] Get Role', props<{ role: Role }>());
export const getRoleFailed = createAction('[Role/API] Get Role Failed', props<{ error: any }>());

export const loadRolesRequest = createAction('[Role/API] Load Roles Request');
export const loadRoles = createAction('[Role/API] Load Roles', props<{ roles: Role[] }>());
export const loadRolesFailed = createAction('[Role/API] Load Roles Failed', props<{ error: any }>());

export const addRoleRequest = createAction('[Role/API] Add Role Request', props<{ role: Role }>());
export const addRole = createAction('[Role/API] Add Role', props<{ role: Role }>());
export const addRoleFailed = createAction('[Role/API] Add Role Failed', props<{ error: any }>());

export const updateRoleRequest = createAction('[Role/API] Update Role Request', props<{ role: Role }>());
export const updateRole = createAction('[Role/API] Update Role', props<{ role: UpdateStr<Role> }>());
export const updateRoleFailed = createAction('[Role/API] Update Role Failed', props<{ error: any }>());

export const deleteRoleRequest = createAction('[Role/API] Delete Role Request', props<{ id: string }>());
export const deleteRole = createAction('[Role/API] Delete Role', props<{ id: string }>());
export const deleteRoleFailed = createAction('[Role/API] Delete Role Failed', props<{ error: any }>());

export const clearRoles = createAction('[Role/API] Clear Roles');
export const selectRole = createAction('[Role/API] Select Role', props<{ id: string }>());
