import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPage from './app-store.reducers';
import * as fromEffects from './effects';

import { CommonBackendModule } from '../../backend/common/common-backend.module';
import { stores } from './store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPage.pageFeatureKey, fromPage.reducers),
    EffectsModule.forFeature(fromEffects.effects),
  ],
  providers: [
    ...CommonBackendModule.forRoot().providers,
    ...stores,
  ],
})
export class AppStoreModule { }
