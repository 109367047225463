import { createReducer, on, Action } from '@ngrx/store';
import * as CategoryFileActions from '../actions/category-file.actions';
import { categoryFileAdapter, CategoryFileState, initialCategoryFileState } from '../state/category-file.state';
import { CategoryFile } from '../../../interfaces/common/category-file';
import { UpdateStr } from '@ngrx/entity/src/models';

const reducer = createReducer(
  initialCategoryFileState,
  on(
    CategoryFileActions.addCategoryFileRequest,
    CategoryFileActions.deleteCategoryFileRequest,
    CategoryFileActions.getCategoryFileRequest,
    CategoryFileActions.loadCategoryFilesRequest,
    CategoryFileActions.updateCategoryFileRequest,
    (state) => {
      return { ...state, isLoading: true, error: null };
  }),
  on(
    CategoryFileActions.addCategoryFileFailed,
    CategoryFileActions.deleteCategoryFileFailed,
    CategoryFileActions.getCategoryFileFailed,
    CategoryFileActions.loadCategoryFilesFailed,
    CategoryFileActions.updateCategoryFileFailed,
    (state, { error }) => {
      return { ...state, isLoading: false, error: error };
  }),
  on(CategoryFileActions.loadCategoryFiles, (state, { categoryFiles }) => {
    return categoryFileAdapter.addAll(categoryFiles, { ...state, isLoading: false, error: null, isLoad: true });
  }),
  on(
    CategoryFileActions.addCategoryFile,
    CategoryFileActions.getCategoryFile,
    (state, { categoryFile }) => {
    return categoryFileAdapter.addOne(categoryFile, { ...state, isLoading: false, error: null });
  }),
  on(
    CategoryFileActions.updateCategoryFile,
    (state, { categoryFile }) => {
      const CategoryFileUpdated: UpdateStr<CategoryFile> = { id: categoryFile.id, changes: categoryFile };
      return categoryFileAdapter.updateOne(CategoryFileUpdated, { ...state, isLoading: false, error: null });
  }),
  on(CategoryFileActions.deleteCategoryFile, (state, { id }) => {
    return categoryFileAdapter.removeOne(id, state);
  }),
  on(CategoryFileActions.clearCategoryFiles, state => {
    return categoryFileAdapter.removeAll({ ...state, selectedCategoryFileId: null, isLoading: false, error: null, isLoad: false });
  }),
  on(CategoryFileActions.selectCategoryFile, (state, { id }) => {
    return { ...state, selectedCategoryFileId: id };
  }),
);

export function categoryFileReducer(state: CategoryFileState | undefined, action: Action) {
  return reducer(state, action);
}
