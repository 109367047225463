import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Role } from '../../../interfaces/common/role';

export interface RoleState extends EntityState<Role> {
  isLoad: boolean;
  isLoading?: boolean;
  error?: any;
  selectedRoleId: string | null;
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: selectRoleId,
  sortComparer: sortByName,
});

export const initialRoleState: RoleState = roleAdapter.getInitialState({
  isLoad: false,
  isLoading: false,
  error: null,
  selectedRoleId: null,
});

function selectRoleId(role: Role): string {
  return role.id;
}

function sortByName(roleA: Role, roleB: Role): number {
  return roleA.normalizedName.localeCompare(roleB.normalizedName);
}
